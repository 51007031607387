/* eslint-disable func-names */
import moment from 'moment';
import { t } from 'i18next';
import { select } from 'd3-selection';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SortOrder, SortByFields } from 'Services/SkorrApi/new/User/types';
import { convertNumberToHumanStringfiedFormat } from 'Services/Utils/Utils';
import { LISTENING_PREVIOUS_SEARCHES_LOCALSTORAGE_KEY } from 'data/constants';

import {
  useGetHashtagsByFilter,
  useGetEvolutionByFilter,
  useGetPublicUsersByFilter,
  useGetPublicPostsByFilter,
  useGetPublicPostsKpisByFilter,
} from '../api';
import { ParamsFilters, SearchesLocalStorage } from '../types';

const wordCloudOptions = {
  colors: [
    '#52ACFE',
    '#2C9AFE',
    '#108AF9',
    '#067EEB',
    '#0271D5',
    '#0264BD',
    '#0559A4',
    '#024F94',
  ],
  padding: 2,
  rotations: 1,
  scale: 'sqrt',
  fontSizes: [10, 50],
  enableTooltip: true,
  deterministic: false,
  spiral: 'archimedean',
  fontFamily: 'Poppins',
  rotationAngles: [0, 90],
  transitionDuration: 1000,
};

export const useListeningTerm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { term } = useParams();
  const { state } = location || {};
  const { filters }: { filters: ParamsFilters } = state || {};

  const [postsPage, setPostsPage] = useState(0);
  const [usersPage, setUsersPage] = useState(0);
  const [tabSelected, setTabSelected] = useState<'posts' | 'creators'>('posts');

  const handleGoBack = useCallback(() => {
    navigate('/listening', {
      state: {
        filters,
      },
    });
  }, [filters]);

  const handleGoToUserMediaKit = useCallback((userId: string) => {
    navigate(`/user-media-kit/${userId}`);
  }, []);

  const diffDays = moment(filters.endDate).diff(
    moment(filters.startDate),
    'days',
  );

  const timeFrame = `${moment(filters.startDate).format('MMM D')} - ${moment(
    filters.endDate,
  ).format('ll')} (${diffDays + t('listeningTerm.days')})`;

  const {
    data: hashtagsByFilter,
    mutateAsync: getHashtagsByFilter,
    isLoading: isLoadingHashtagsByFilter,
  } = useGetHashtagsByFilter();

  const relatedTopics = useMemo(
    () =>
      hashtagsByFilter?.map(tag => {
        return {
          text: tag.hashtag,
          value: tag.numPosts,
        };
      }),
    [hashtagsByFilter],
  );

  const handleGetHashtagsByFilter = useCallback(async () => {
    await getHashtagsByFilter({
      filters,
    });
  }, [getHashtagsByFilter, filters]);

  const {
    data: evolutionByFilter,
    mutateAsync: getEvolutionByFilter,
    isLoading: isLoadingEvolutionByFilter,
  } = useGetEvolutionByFilter();

  const chartData = useCallback(() => {
    const categoriesData: string[] = [];
    const engagementData: number[] = [];

    (evolutionByFilter || []).forEach(item => {
      categoriesData.push(item.day);
      engagementData.push(item.kpis.reactions);
    });

    return {
      categories: categoriesData,
      lineData: [{ data: engagementData, name: term }],
    };
  }, [evolutionByFilter, term]);

  const chartOptions = {
    chart: {
      type: 'line',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 3,
      curve: 'smooth',
      connectNulls: true,
    },
    xaxis: {
      tickAmount: 6,
      type: 'datetime',
      categories: chartData().categories,
      labels: {
        offsetY: -0,
        rotate: -45,
        trim: false,
        rotateAlways: false,
        hideOverlappingLabels: true,
      },
    },
    markers: {
      size: 0,
      opacity: 0.5,
      strokeWidth: 1,
      strokeColor: '#fff',
      hover: {
        size: 5,
      },
    },
    yaxis: {
      labels: {
        show: true,
        offsetX: 7,
        formatter(tickAmount: number) {
          return convertNumberToHumanStringfiedFormat(tickAmount.toFixed(2), 2);
        },
      },
    },
  };

  const handleGetEvolutionByFilter = useCallback(async () => {
    await getEvolutionByFilter({
      filters,
    });
  }, [getEvolutionByFilter, filters]);

  const { data: publicPostsByFilter, mutateAsync: getPublicPostsByFilter } =
    useGetPublicPostsByFilter();

  const handleGetPublicPostsByFilter = useCallback(
    async (pageNumber?: number) => {
      await getPublicPostsByFilter({
        filters,
        limit: 48,
        page: pageNumber ?? postsPage,
        sortBy: 'date' as SortByFields,
        sortOrder: SortOrder.DESC,
      });
    },
    [getPublicPostsByFilter, postsPage, filters],
  );

  const handleChangePostsPage = useCallback(
    (pageNumber: number) => {
      setPostsPage(pageNumber);

      setTimeout(() => {
        handleGetPublicPostsByFilter(pageNumber);
      }, 100);
    },
    [handleGetPublicPostsByFilter],
  );

  const {
    data: publicPostsKpisByFilter,
    mutateAsync: getPublicPostsKpisByFilter,
    isLoading: isLoadingPublicPostsKpisByFilter,
  } = useGetPublicPostsKpisByFilter();

  const handleGetPublicPostsKpisByFilter = useCallback(async () => {
    await getPublicPostsKpisByFilter(filters);
  }, [getPublicPostsKpisByFilter, filters]);

  const {
    data: top5Users,
    mutateAsync: getTop5Users,
    isLoading: isLoadingTop5Users,
  } = useGetPublicUsersByFilter();

  const handleGetTop5Users = useCallback(async () => {
    await getTop5Users({
      filters,
      page: 0,
      limit: 5,
      sort: SortByFields.engagement_rate,
      sortOrder: SortOrder.DESC,
    });
  }, [getTop5Users, filters]);

  const { data: publicUsersByFilter, mutateAsync: getPublicUsersByFilter } =
    useGetPublicUsersByFilter();

  const handleGetPublicUsersByFilter = useCallback(
    async (pageNumber?: number) => {
      await getPublicUsersByFilter({
        filters,
        limit: 48,
        page: pageNumber ?? usersPage,
        sort: SortByFields.audience,
        sortOrder: SortOrder.DESC,
      });
    },
    [getPublicUsersByFilter, usersPage, filters],
  );

  const handleChangeUsersPage = useCallback(
    (pageNumber: number) => {
      setUsersPage(pageNumber);

      setTimeout(() => {
        handleGetPublicUsersByFilter(pageNumber);
      }, 100);
    },
    [handleGetPublicUsersByFilter],
  );

  const isLoadingData =
    isLoadingTop5Users ||
    isLoadingHashtagsByFilter ||
    isLoadingEvolutionByFilter ||
    isLoadingPublicPostsKpisByFilter;

  const noDataToShow =
    top5Users?.total === 0 &&
    hashtagsByFilter?.length === 0 &&
    evolutionByFilter?.length === 0 &&
    publicPostsByFilter?.total === 0 &&
    Object.values(publicPostsKpisByFilter || {}).length === 0;

  const getCallback = (callback: string) => {
    return function (word: any, event: any) {
      const isActive = callback !== 'onWordMouseOut';
      const element = event.target;
      const text = select(element);
      text
        .on('click', () => {
          if (isActive && word.text.trim().length > 2) {
            navigate(`/listening/${word.text.trim()}`, {
              state: {
                filters: { ...filters, keywords: [word.text] },
              },
              replace: true,
            });
          }
        })
        .attr('text-decoration', isActive ? 'underline' : 'none');
    };
  };

  const callbacks = useMemo(
    () => ({
      getWordTooltip: (word: any) =>
        `The "#${word.text}" appears in ${word.value} posts.`,
      onWordClick: getCallback('onWordClick'),
      onWordMouseOut: getCallback('onWordMouseOut'),
      onWordMouseOver: getCallback('onWordMouseOver'),
    }),
    [],
  );

  useEffect(() => {
    handleGetHashtagsByFilter();
    handleGetEvolutionByFilter();
    handleGetPublicPostsByFilter();
    handleGetPublicPostsKpisByFilter();
    handleGetTop5Users();
    handleGetPublicUsersByFilter();
  }, [filters]);

  useEffect(() => {
    if (publicPostsKpisByFilter && term) {
      const newSearch = {
        term,
        numUsers: publicPostsKpisByFilter.numUsers,
        numPosts: publicPostsKpisByFilter.numPosts,
        avgEng: publicPostsKpisByFilter.reactions,
        filters,
      };

      const existingSearches: SearchesLocalStorage[] = JSON.parse(
        localStorage.getItem(LISTENING_PREVIOUS_SEARCHES_LOCALSTORAGE_KEY) ||
          '[]',
      );

      const index = existingSearches.findIndex(
        item => item.term === newSearch.term,
      );

      if (index !== -1) {
        existingSearches[index] = { ...existingSearches[index], ...newSearch };
      } else {
        existingSearches.push(newSearch);
      }

      localStorage.setItem(
        LISTENING_PREVIOUS_SEARCHES_LOCALSTORAGE_KEY,
        JSON.stringify(existingSearches),
      );
    }
  }, [publicPostsKpisByFilter, term]);

  return {
    term,
    postsPage,
    usersPage,
    timeFrame,
    chartData,
    top5Users,
    callbacks,
    tabSelected,
    chartOptions,
    handleGoBack,
    noDataToShow,
    relatedTopics,
    isLoadingData,
    setTabSelected,
    wordCloudOptions,
    hashtagsByFilter,
    evolutionByFilter,
    publicPostsByFilter,
    publicUsersByFilter,
    handleChangePostsPage,
    handleChangeUsersPage,
    handleGoToUserMediaKit,
    publicPostsKpisByFilter,
  };
};
