/* eslint-disable no-use-before-define */
/* eslint-disable no-empty-function */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-escape */
/* eslint-disable default-case */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable import/extensions */
import InfoV2Icon from 'assets/iconComponents/InfoIconV2';
import InfoV3Icon from 'assets/iconComponents/InfoIconV3';
import InstagramIcon from 'assets/iconComponents/InstagramIcon';
import PlusIconV2 from 'assets/iconComponents/PlusIconV2';
import TiktokIcon from 'assets/iconComponents/TiktokIcon';
import VerifiedIconV1 from 'assets/iconComponents/VerifiedIconV1';
import GenericModal from 'components/shared/GenericModal/GenericModal';
import { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { LoginSocialTiktok } from 'reactjs-social-login';
import { fbLogin } from 'Services/Utils/facebookSdk';
import {
  DEFAULT_USER_AVATAR,
  SocialNetworks,
  SocialNetworkStatusType,
} from 'Services/Utils/types';
import { getSocialNetworkIcon } from 'Services/Utils/Utils';
import { OuterContext } from 'components/MenuNavbarV2/MenuNavbarV2';

import * as S from './Styles';
import { SyncWithOfficialAccountsModalProps } from './types';

enum CAN_OFFICIAL_SOCIAL_ACCOUNT_BE_ADDED_OUTPUT {
  'MAX_REACHED' = 'MAX_REACHED',
  'ALREADY_ADDED' = 'ALREADY_ADDED',
  'PERMITTED' = 'PERMITTED',
}

const SyncWithOfficialAccountsModal = ({
  facebookLoginResponseData,
  setFacebookLoginResponseData,
  facebookAccountsToSync,
  tiktokAccountsToSync,
  publicProfiles,
  setPublicProfiles,
  setShowInstagramLoginButtonOverlaySpinner,
  setIsGetFacebookAccountsToSyncEnabled,
  isFetchingGetFacebookAccountsToSync,
  showInstagramLoginButtonOverlaySpinner,
  isFetchingGetTiktokAccountsToSync,
  showTiktokLoginButtonOverlaySpinner,
  setShowSyncWithOfficialDataModal,
  isErrorGetFacebookAccountsToSync,
  isErrorGetTiktokAccountsToSync,
  t,
}: SyncWithOfficialAccountsModalProps) => {
  const tiktokAuthCodeContext = useContext(OuterContext);
  const canOfficialSocialAccountBeAdded = (
    username: string,
    socialNetwork: SocialNetworks,
  ): CAN_OFFICIAL_SOCIAL_ACCOUNT_BE_ADDED_OUTPUT => {
    if (
      publicProfiles.find(
        publicProfile =>
          publicProfile.username == username &&
          publicProfile.socialNetwork == socialNetwork &&
          publicProfile.syncStatus == SocialNetworkStatusType.OK,
      )
    )
      return CAN_OFFICIAL_SOCIAL_ACCOUNT_BE_ADDED_OUTPUT.ALREADY_ADDED;

    if (
      publicProfiles.length == 3 &&
      !publicProfiles.find(
        publicProfile =>
          publicProfile.username == username &&
          publicProfile.socialNetwork == socialNetwork,
      )
    )
      return CAN_OFFICIAL_SOCIAL_ACCOUNT_BE_ADDED_OUTPUT.MAX_REACHED;

    return CAN_OFFICIAL_SOCIAL_ACCOUNT_BE_ADDED_OUTPUT.PERMITTED;
  };

  const officialSocialAccountAddButtonClicked = (
    username: string,
    socialNetwork: SocialNetworks,
    displayPicture: string,
    profileId: string,
  ) => {
    if (
      !publicProfiles.find(
        publicProfile =>
          publicProfile.username == username &&
          publicProfile.socialNetwork == socialNetwork,
      )
    ) {
      setPublicProfiles(
        [
          ...publicProfiles,
          {
            publicUserId: null,
            username,
            socialNetwork,
            displayPicture,
            syncStatus: SocialNetworkStatusType.OK,
            socialUserProfileId: profileId,
          },
        ].sort((profileA, profileB) =>
          profileA.username < profileB.username ? -1 : 1,
        ),
      );
    } else {
      const publicProfilesLocal = [...publicProfiles];
      const addedSocialAccount = publicProfilesLocal.find(
        publicProfile =>
          publicProfile.username == username &&
          publicProfile.socialNetwork == socialNetwork,
      );

      addedSocialAccount!.syncStatus = SocialNetworkStatusType.OK;
      addedSocialAccount!.socialUserProfileId = profileId;

      setPublicProfiles(
        [...publicProfilesLocal].sort((profileA, profileB) =>
          profileA.username < profileB.username ? -1 : 1,
        ),
      );
    }
  };

  const loginWithInstagramButtonClicked = async () => {
    setShowInstagramLoginButtonOverlaySpinner(true);

    sessionStorage.removeItem(
      `fbssls_${process.env.REACT_APP_FACEBOOK_APP_ID}`,
    );

    (window as any).FB.getLoginStatus((response: any) => {
      if (response.status == 'connected') {
        setFacebookLoginResponseData({
          accessToken: response.authResponse.accessToken,
          profileId: response.authResponse.userID,
          status: response.status,
        });
        setIsGetFacebookAccountsToSyncEnabled(true);
      } else {
        fbLogin({
          successCallback: response => {
            setFacebookLoginResponseData({
              accessToken: response.authResponse.accessToken,
              profileId: response.authResponse.userID,
              status: response.status,
            });
            setShowInstagramLoginButtonOverlaySpinner(true);
            setIsGetFacebookAccountsToSyncEnabled(true);
          },
          errorCallback: () => {
            setShowInstagramLoginButtonOverlaySpinner(false);
          },
        });
      }
    });
  };

  const getDescription = () => {
    return (
      <>
        <S.SubTitle>
          {t('socialAccountManagement.syncWithOfficialData.subtitlePart1')}
        </S.SubTitle>

        <S.SyncWithOfficialDataSubtitleBulletPoints>
          <S.SyncWithOfficialDataSubtitleBulletPoint>
            <S.SyncWithOfficialDataSubtitleDotContainer>
              <S.SyncWithOfficialDataSubtitleDot />
            </S.SyncWithOfficialDataSubtitleDotContainer>

            {t(
              'socialAccountManagement.syncWithOfficialData.subtitlePart2BulletPoints.first',
            )}
          </S.SyncWithOfficialDataSubtitleBulletPoint>

          <S.SyncWithOfficialDataSubtitleBulletPoint>
            <S.SyncWithOfficialDataSubtitleDotContainer>
              <S.SyncWithOfficialDataSubtitleDot />
            </S.SyncWithOfficialDataSubtitleDotContainer>

            {t(
              'socialAccountManagement.syncWithOfficialData.subtitlePart2BulletPoints.second',
            )}
          </S.SyncWithOfficialDataSubtitleBulletPoint>

          <S.SyncWithOfficialDataSubtitleBulletPoint>
            <S.SyncWithOfficialDataSubtitleDotContainer>
              <S.SyncWithOfficialDataSubtitleDot />
            </S.SyncWithOfficialDataSubtitleDotContainer>

            {t(
              'socialAccountManagement.syncWithOfficialData.subtitlePart2BulletPoints.third',
            )}
          </S.SyncWithOfficialDataSubtitleBulletPoint>

          <S.SyncWithOfficialDataSubtitleBulletPoint>
            <S.SyncWithOfficialDataSubtitleDotContainer>
              <S.SyncWithOfficialDataSubtitleDot />
            </S.SyncWithOfficialDataSubtitleDotContainer>

            {t(
              'socialAccountManagement.syncWithOfficialData.subtitlePart2BulletPoints.fourth',
            )}
          </S.SyncWithOfficialDataSubtitleBulletPoint>
        </S.SyncWithOfficialDataSubtitleBulletPoints>
      </>
    );
  };

  const getNetworkSections = () => {
    return (
      <S.SyncWithOfficialDataInnerContainer>
        {getNetworkSection(SocialNetworks.INSTAGRAM)}

        {getNetworkSection(SocialNetworks.TIKTOK)}
      </S.SyncWithOfficialDataInnerContainer>
    );
  };

  const getNetworkSection = (socialNetwork: SocialNetworks) => {
    return (
      <S.SyncWithOfficialDataNetworkContainer>
        {getLoginButton(socialNetwork)}

        {getOfficialAccountsSection(socialNetwork)}

        {getNoOfficialAccountsWereFoundSection(socialNetwork)}

        {getLoginButtonOverlaySpinner(socialNetwork)}
      </S.SyncWithOfficialDataNetworkContainer>
    );
  };

  const getLoginButton = (socialNetwork: SocialNetworks) => {
    if (socialNetwork == SocialNetworks.INSTAGRAM) {
      if (
        facebookLoginResponseData?.status !== 'connected' ||
        isFetchingGetFacebookAccountsToSync ||
        showInstagramLoginButtonOverlaySpinner ||
        isErrorGetFacebookAccountsToSync
      ) {
        return (
          <S.LoginWithInstagramButton
            onClick={() => loginWithInstagramButtonClicked()}
          >
            <InstagramIcon
              containerWidth="2rem"
              containerHeight="2rem"
              noAssignColor={true}
            />
            {t(
              'socialAccountManagement.syncWithOfficialData.loginWithInstagram',
            )}
            <S.HiddenElement className="hidden-element" />
          </S.LoginWithInstagramButton>
        );
      }
    }

    if (socialNetwork == SocialNetworks.TIKTOK) {
      if (
        !tiktokAuthCodeContext?.tiktokAuthCode ||
        isFetchingGetTiktokAccountsToSync ||
        showTiktokLoginButtonOverlaySpinner ||
        isErrorGetTiktokAccountsToSync
      ) {
        return (
          <LoginSocialTiktok
            client_key={process.env.REACT_APP_TIKTOK_CLIENT_KEY!}
            redirect_uri={process.env.REACT_APP_TIKTOK_REDIRECT_URI!}
            onLoginStart={() => console.log('TIKTOK LOGIN STARTED')}
            onResolve={() => console.log('TIKTOK LOGIN RESOLVED')}
            onReject={() => console.log('TIKTOK LOGIN REJECTED')}
          >
            <S.LoginWithTiktokButton>
              <TiktokIcon containerWidth="2.5rem" containerHeight="2.5rem" />
              {t(
                'socialAccountManagement.syncWithOfficialData.loginWithTiktok',
              )}
              <S.HiddenElement className="hidden-element" />
            </S.LoginWithTiktokButton>
          </LoginSocialTiktok>
        );
      }
    }

    return null;
  };

  const getOfficialAccountsSection = (socialNetwork: SocialNetworks) => {
    const show =
      socialNetwork == SocialNetworks.INSTAGRAM
        ? facebookAccountsToSync && facebookAccountsToSync.length > 0
        : tiktokAccountsToSync && tiktokAccountsToSync?.length > 0;

    if (show) {
      return (
        <S.OfficialSocialAccountsContainer>
          {getOfficialAccountsSectionTitle(socialNetwork)}

          {getOfficialAccounts(socialNetwork)}
        </S.OfficialSocialAccountsContainer>
      );
    }

    return null;
  };

  const getOfficialAccountsSectionTitle = (socialNetwork: SocialNetworks) => {
    const accounts =
      socialNetwork == SocialNetworks.INSTAGRAM
        ? facebookAccountsToSync
        : tiktokAccountsToSync;

    return (
      <S.OfficialSocialAccountsTitle>
        <InfoV2Icon
          containerHeight="3rem"
          containerWidth="3rem"
          containerMarginRight="1rem"
        />
        <S.OfficialSocialAccountsTitleLabel>
          {`${t(
            'socialAccountManagement.officialSocialAccounts.officialAccountsFoundDescriptionPart1',
          )}`}
          <b>
            {`${accounts!.length} ${
              accounts!.length == 1
                ? t(
                    `socialAccountManagement.officialSocialAccounts.${
                      socialNetwork == SocialNetworks.INSTAGRAM
                        ? 'instagram'
                        : 'tiktok'
                    }Account`,
                  )
                : t(
                    `socialAccountManagement.officialSocialAccounts.${
                      socialNetwork == SocialNetworks.INSTAGRAM
                        ? 'instagram'
                        : 'tiktok'
                    }Accounts`,
                  )
            }`}
          </b>
          {`${t(
            'socialAccountManagement.officialSocialAccounts.officialAccountsFoundDescriptionPart2',
          )}`}
        </S.OfficialSocialAccountsTitleLabel>
      </S.OfficialSocialAccountsTitle>
    );
  };

  const getOfficialAccounts = (socialNetwork: SocialNetworks) => {
    const accountsToSync =
      socialNetwork == SocialNetworks.INSTAGRAM
        ? facebookAccountsToSync
        : tiktokAccountsToSync;

    return (
      <S.OfficialSocialAccountsInnerContainer>
        {accountsToSync!.map(account => (
          <S.OfficialSocialAccountContainer>
            <S.OfficialSocialAccountLeftContainer>
              <S.OfficialSocialAccountImage
                src={account.displayPicture ?? DEFAULT_USER_AVATAR}
              />
              <S.OfficialSocialAccountNetworkIconContainer>
                {getSocialNetworkIcon({
                  socialNetwork,
                  width: '2rem',
                  height: '2rem',
                  gradientColored: true,
                })}
              </S.OfficialSocialAccountNetworkIconContainer>
            </S.OfficialSocialAccountLeftContainer>

            <S.OfficialSocialAccountCenterContainer>
              <S.OfficialSocialAccountUsernameContainer>
                <S.OfficialSocialAccountUsername>
                  {`@${account.username}`}
                </S.OfficialSocialAccountUsername>
                <VerifiedIconV1
                  containerWidth="1.7rem"
                  containerHeight="1.7rem"
                  containerMarginLeft="0.3rem"
                />
              </S.OfficialSocialAccountUsernameContainer>
              <S.OfficialSocialAccountNetworkName>
                {socialNetwork[0] + socialNetwork.slice(1).toLowerCase()}
              </S.OfficialSocialAccountNetworkName>
            </S.OfficialSocialAccountCenterContainer>

            <S.OfficialSocialAccountRightContainer>
              {canOfficialSocialAccountBeAdded(
                account.username,
                socialNetwork,
              ) == CAN_OFFICIAL_SOCIAL_ACCOUNT_BE_ADDED_OUTPUT.PERMITTED && (
                <S.OfficialSocialAccountAddContainer
                  onClick={() =>
                    officialSocialAccountAddButtonClicked(
                      account.username,
                      socialNetwork,
                      account.displayPicture,
                      account.profileId,
                    )
                  }
                >
                  <PlusIconV2 containerWidth="2rem" containerHeight="2rem" />
                  <span>
                    {t(
                      'socialAccountManagement.officialSocialAccounts.addAccount',
                    )}
                  </span>
                </S.OfficialSocialAccountAddContainer>
              )}
              {canOfficialSocialAccountBeAdded(
                account.username,
                socialNetwork,
              ) ==
                CAN_OFFICIAL_SOCIAL_ACCOUNT_BE_ADDED_OUTPUT.ALREADY_ADDED && (
                <S.OfficialSocialAccountAlreadyAddContainer>
                  <span>{t('generic.added')}</span>
                </S.OfficialSocialAccountAlreadyAddContainer>
              )}
              {canOfficialSocialAccountBeAdded(
                account.username,
                socialNetwork,
              ) == CAN_OFFICIAL_SOCIAL_ACCOUNT_BE_ADDED_OUTPUT.MAX_REACHED && (
                <S.MaxAccountNumberReachedContainer>
                  <InfoV3Icon containerWidth="2rem" containerHeight="2rem" />
                  <S.MaxAccountNumberReached>
                    {t(
                      'socialAccountManagement.officialSocialAccounts.maximumAccountCountReached',
                    )}
                  </S.MaxAccountNumberReached>
                </S.MaxAccountNumberReachedContainer>
              )}
            </S.OfficialSocialAccountRightContainer>
          </S.OfficialSocialAccountContainer>
        ))}
      </S.OfficialSocialAccountsInnerContainer>
    );
  };

  const getNoOfficialAccountsWereFoundSection = (
    socialNetwork: SocialNetworks,
  ) => {
    const showNoAccountsFoundSection =
      socialNetwork == SocialNetworks.INSTAGRAM
        ? facebookLoginResponseData?.status == 'connected' &&
          !isFetchingGetFacebookAccountsToSync &&
          facebookAccountsToSync?.length == 0
        : tiktokAuthCodeContext.tiktokAuthCode &&
          !isFetchingGetTiktokAccountsToSync &&
          tiktokAccountsToSync?.length == 0;

    if (showNoAccountsFoundSection) {
      return (
        <S.OfficialSocialAccountsContainer>
          <S.OfficialSocialAccountsTitle>
            <InfoV2Icon
              containerHeight="3rem"
              containerWidth="3rem"
              containerMarginRight="1rem"
            />
            <S.OfficialSocialAccountsTitleLabel>
              {t(
                `socialAccountManagement.officialSocialAccounts.no${
                  socialNetwork == SocialNetworks.INSTAGRAM
                    ? 'Instagram'
                    : 'Tiktok'
                }AccountsWereFound`,
              )}
            </S.OfficialSocialAccountsTitleLabel>
          </S.OfficialSocialAccountsTitle>
        </S.OfficialSocialAccountsContainer>
      );
    }

    return null;
  };

  const getLoginButtonOverlaySpinner = (socialNetwork: SocialNetworks) => {
    const showOverlaySpinner =
      socialNetwork == SocialNetworks.INSTAGRAM
        ? isFetchingGetFacebookAccountsToSync ||
          showInstagramLoginButtonOverlaySpinner
        : isFetchingGetTiktokAccountsToSync ||
          showTiktokLoginButtonOverlaySpinner;

    if (showOverlaySpinner) {
      return (
        <S.LoginButtonLoadingOverlay>
          <S.LoginButtonLoadingOverlaySpinner />
        </S.LoginButtonLoadingOverlay>
      );
    }

    return null;
  };

  return (
    <GenericModal
      show={true}
      title={t('socialAccountManagement.syncWithOfficialData.title')}
      body={
        <S.SyncWithOfficialDataContainer>
          {getDescription()}

          {getNetworkSections()}
        </S.SyncWithOfficialDataContainer>
      }
      primaryButtonText={t('generic.close')}
      primaryButtonAction={() => setShowSyncWithOfficialDataModal(false)}
      modalWidth={'90rem'}
    />
  );
};

export default withTranslation()(SyncWithOfficialAccountsModal);
