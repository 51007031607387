import { SocialNetworks } from 'Services/Utils/types';

export type ParamsFilters = {
  endDate: number;
  startDate: number;
  keywords: string[];
  countryCode: string[];
  network: SocialNetworks[] | undefined;
};

export type SearchesLocalStorage = {
  term: string;
  avgEng: number;
  numUsers: number;
  numPosts: number;
  filters: ParamsFilters;
};

export enum ListeningSortByField {
  'creators' = 'creators',
  'posts' = 'posts',
  'avgEng' = 'avgEng',
}
